<template>
  <Transition name="overlay">
    <div v-if="displayOverlay" class="drawer-overlay">
      <Transition name="drawer-slide">
        <div v-if="displayDrawer" class="drawer" ref="drawer">
          <div class="drawer-header">
            <h2>{{ title }}</h2>
            <img
              :src="require('@/assets/svg/v2/registerProcess/delete.svg')"
              class="themed-img"
              alt="Close icon"
              @click="close"
            />
          </div>
          <div class="drawer-content">
            <slot></slot>
          </div>
        </div>
      </Transition>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'commonDrawer',
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      displayOverlay: false,
      displayDrawer: false,
    }
  },
  methods: {
    close() {
      this.displayDrawer = false

      setTimeout(() => {
        this.displayOverlay = false
      }, 100)

      setTimeout(() => {
        this.$emit('close')
      }, 200)
    },
    handleClickOutsideDrawer(event) {
      const drawer = this.$refs.drawer
      if (drawer && !drawer.contains(event.target)) {
        this.close()
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.displayOverlay = true
    }, 100)
    setTimeout(() => {
      this.displayDrawer = true
    }, 200)

    document.addEventListener('mousedown', this.handleClickOutsideDrawer)
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideDrawer)
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';

.drawer-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color-8);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: opacity 0.3s ease;
  backdrop-filter: blur(5px);
}

.drawer {
  background-color: var(--background-color-2);
  height: 100vh;
  width: 40%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease 0.3s;

  .drawer-header {
    height: 8%;
    padding: 0 1rem;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;

    > img {
      cursor: pointer;
    }
  }

  .drawer-content {
    height: 92%;
    width: 100%;
  }
}

.overlay-enter-active,
.overlay-leave-active {
  transition: opacity 0.5s ease;
}

.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
}

.drawer-slide-enter-active,
.drawer-slide-leave-active {
  transition: transform 0.3s ease;
}

.drawer-slide-enter-from {
  transform: translateX(100%);
}

.drawer-slide-enter-to,
.drawer-slide-leave-from {
  transform: translateX(0);
}

.drawer-slide-leave-to {
  transform: translateX(100%);
}
</style>
