<template>
  <div class="tooltip-content">
    <div class="item" @click="clickOnDetailBtn">
      {{ $t('portfolio.see_details') }}
    </div>
    <el-divider class="divider" />
    <div class="item" @click="clickOnProductPageBtn">
      {{ $t('portfolio.go_to_product_page') }}
    </div>
    <el-divider class="divider" />
    <div class="red" @click="clickOnDeleteBottle">
      {{ $t('portfolio.delete_bottle') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'bottleOptionsTooltip',
  emits: ['openDetails', 'toProductPage', 'deleteBottle'],
  methods: {
    clickOnDetailBtn() {
      this.$emit('openDetails')
    },
    clickOnProductPageBtn() {
      this.$emit('toProductPage')
    },
    clickOnDeleteBottle() {
      this.$emit('deleteBottle')
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';

.tooltip-content {
  white-space: nowrap;
  padding: 0.4rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 3px;

  .red {
    color: $alert-color;
  }

  .item {
    color: var(--text-color);
  }
}

.divider {
  border-color: var(--border-2);
  margin: 0;
}
</style>
