<template>
  <div class="button action-light" @click="handleButtonClick">
    <div class="button-action">
      {{ $t('portfolio.add_button') }}
    </div>
    <div class="button-row">
      <img
        :src="require('@/assets/svg/v2/arrowToBottom.svg')"
        class="themed-img"
        alt="Create button icon"
      />
    </div>

    <div v-if="showMenuItems" ref="menuWrapper" class="button-menu__wrapper">
      <div class="button-menu">
        <div
          v-for="item in toCreate"
          :key="item.value"
          class="button-menu__items"
          @click="handleMenuItemClick(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>

  <CommonModal
    v-if="isCreateModalOpened"
    size="small"
    :modal-title="modalTitle"
    @close="closeModal"
  >
    <CreateFolderForm
      v-if="clickedItemValue === 'FOLDER'"
      @close="closeModal"
    />
    <CreateBottleForm
      v-if="clickedItemValue === 'BOTTLE'"
      @closeModal="closeModal"
    />
  </CommonModal>
</template>

<script>
import CreateFolderForm from '@/components/modules/portfolio/createFolderForm.vue'
import CommonModal from '@/components/commons/modal.vue'
import CreateBottleForm from '@/components/modules/portfolio/createBottleForm/createBottleForm.vue'

export default {
  name: 'addButton',
  components: { CreateBottleForm, CommonModal, CreateFolderForm },
  data() {
    return {
      toCreate: [
        {
          label: this.$t('portfolio.folder'),
          value: 'FOLDER',
        },
        {
          label: this.$t('portfolio.bottle'),
          value: 'BOTTLE',
        },
      ],
      clickedItemValue: null,
      showMenuItems: false,
      isCreateModalOpened: false,
      modalTitle: '',
    }
  },
  methods: {
    handleButtonClick() {
      this.showMenuItems = true
    },
    handleMenuItemClick(itemValue) {
      this.showMenuItems = false
      this.clickedItemValue = itemValue

      if (this.clickedItemValue === 'FOLDER') {
        this.modalTitle = this.$t('portfolio.add_folder_title')
      }

      if (this.clickedItemValue === 'BOTTLE') {
        this.modalTitle = this.$t('portfolio.add_bottle_title')
      }

      this.isCreateModalOpened = true
    },
    handleClickOutsideMenu(event) {
      const menu = this.$refs.menuWrapper
      if (menu && !menu.contains(event.target)) {
        this.showMenuItems = false
      }
    },
    closeModal() {
      this.isCreateModalOpened = false
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutsideMenu)
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideMenu)
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root';

.button {
  position: relative;
  display: flex;
  max-width: 270px;
  border-radius: 8px;
  color: var(--text-color);
}

.button-action,
.button-row {
  display: flex;
  align-items: center;
  background: $primary-color;
  gap: 8px;
  cursor: pointer;
}

.button-action {
  width: 100px;
  padding: 6px 10px;
  border-radius: 8px 0 0 8px;
  cursor: pointer;
}

.button-row {
  flex-grow: 1;
  display: flex;
  justify-content: center; 
  width: 40px;
  border-left: 1px solid var(--border-2);
  border-radius: 0 8px 8px 0;
}

.button-menu__wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  transform: translateY(110%);

}

.button-menu {
  background-color: var(--background-color-2);
  border: 1px solid var(--border-2);
  border-radius: 8px;
  overflow: hidden;
  
}

.button-menu__items {
  padding: 10px;
  cursor: pointer;
  color: var(--text-color);
  &:hover {
    background: $primary-color;
    color: $light-color;
  }
}
</style>
