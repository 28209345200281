<template>
  <div>
    <Teleport to="body" v-if="isVisible">
      <div class="confirm-overlay">
        <div class="confirm">
          <div class="confirm-content">
            <h3 class="confirm-title">{{ message }}</h3>

            <div class="confirm-buttons">
              <button class="button cancel-button" @click="cancel">
                {{ cancelButtonText }}
              </button>
              <button class="button validate-button" @click="validate">
                {{ validateButtonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
export default {
  name: 'commonConfirm',
  emits: ['confirm'],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true,
    },
    message: {
      type: String,
      required: false,
      default: 'Êtes-vous sûr ?',
    },
    cancelButtonText: {
      type: String,
      default: 'Annuler',
    },
    validateButtonText: {
      type: String,
      default: 'Confirmer',
    },
  },
  data() {
    return {
      resolve: null,
    }
  },
  methods: {
    confirm() {
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    validate() {
      this.resolve(true)
    },
    cancel() {
      this.resolve(false)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';

.confirm-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color-8);
  display: flex;
  align-items: center;
  backdrop-filter: blur(5px);
}

.confirm {
  background-color: var(--background-color-2);
  min-height: 100px;
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
}

.confirm-content {
  min-width: 300px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.confirm-title {
  color: var(--text-color);
}

.confirm-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  .button {
    padding: 8px;
    border: none;
    border-radius: 8px;
    color: var(--text-color);
    font-weight: bold;
  }

  .cancel-button {
    background-color: var(--background-color-4);
  }

  .validate-button {
    background-color: $alert-color;
  }
}
</style>
