<template>
  <div class="form-wrapper">
    <div v-if="!isSwap" class="sell-or-buy">
      <span class="sell-text" :class="{ 'green-text': bottleStatus === 0 }">{{
        $t('portfolio.purchase')
      }}</span>
      <el-switch v-model="bottleStatus" :active-value="1" :inactive-value="0" />
      <span class="sell-text" :class="{ 'red-text': bottleStatus === 1 }">{{
        $t('portfolio.sale')
      }}</span>
    </div>

    <el-divider v-if="!isSwap" class="divider" />

    <div class="details-infos">
      <div class="info">
        <label v-if="!isSwap" for="purchaseDate">{{
          bottleStatus ? $t('portfolio.sellDate') : $t('portfolio.purchaseDate')
        }}</label>
        <label v-else for="purchaseDate">{{ $t('portfolio.swap_date') }}</label>

        <div style="color: white">
          <el-date-picker
            v-model="purchaseDate"
            type="date"
            :clearable="false"
            :editable="false"
            format="DD/MM/YYYY"
            value-format="YYYY-MM-DD"
          />
        </div>
        <span class="error-message">{{ formErrors.purchaseDate }}</span>
      </div>

      <div class="info">
        <label for="quantity">{{ $t('portfolio.th_quantity') }}</label>

        <input v-model="quantity" min="1" id="quantity" type="number" />
        <span class="error-message">{{ formErrors.quantity }}</span>
      </div>
    </div>

    <div class="product-price-infos">
      <div class="net-price">
        <label for="net-price">{{ $t('portfolio.th_net_price') }}</label>

        <div>
          <input
            v-model="netPrice"
            type="text"
            id="net-price"
            name="net-price"
          />
          <div>EUR (€)</div>
        </div>
        <span class="error-message">{{ formErrors.netPrice }}</span>
      </div>

      <div class="price-checkbox">
        <el-checkbox v-model="showPriceOptions" />
        <p>{{ $t('portfolio.addPriceOptions') }}</p>
      </div>

      <div v-if="showPriceOptions" class="price-options">
        <div>
          <label for="price-brut">{{ $t('portfolio.th_gross_price') }}</label>
          <input
            v-model="priceBrut"
            type="text"
            id="price-brut"
            name="price-brut"
            class="input"
          />
        </div>

        <div>
          <label for="price-fees">{{
            $t('portfolio.price_with_commission')
          }}</label>
          <input
            v-model="priceFees"
            type="text"
            id="price-fees"
            name="price-fees"
            class="input"
          />
        </div>
      </div>

      <div class="price-checkbox">
        <el-checkbox v-model="isSwap" />
        <p>Swap</p>
      </div>
      <div v-if="isSwap" class="sell-or-buy">
        <span :class="{ 'sell-text': true, 'giver-text': !swapStatus }"
          >Giver</span
        >
        <el-switch
          v-model="swapStatus"
          :active-value="1" :inactive-value="0"
        />
        <span :class="{ 'sell-text': true, 'taker-text': swapStatus }"
          >Taker</span
        >
      </div>
      <div class="price-checkbox">
        <el-checkbox />
        <p>In bond</p>
      </div>
    </div>

    <div class="storage-infos">
      <label for="storage-area">{{
        $t('portfolio.th_storage_location')
      }}</label>

      <el-select
        v-model="storageLocation"
        name="storage-area"
        id="storage-area"
        :effect="darkmode ? 'dark' : ''"
      >
        <el-option
          v-for="location in getLists.port_location"
          :key="location.value"
          :label="location.label"
          :value="location.value"
        >
        </el-option>
      </el-select>
    </div>

    <el-divider class="divider" />

    <!-- <div class="parent-folder-infos">
        <label>Sélectionnez le dossier parent</label>
  
        <el-select v-model="parentFolder" name="parent-folder" id="parent-folder" :effect="darkmode ? 'dark' : ''">
          <el-option v-for="folder in folderList" :key="folder.value" :label="folder.label" :value="folder.value">
            {{folder.label}}
          </el-option>
        </el-select>
      </div> -->

    <button
      class="submit-button action-light"
      @click="submitForm"
      :disabled="isButtonDisabled"
    >
      {{ $t('portfolio.modify_bottle') }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { inject } from "vue"

export default {
  name: 'updateBottleForm',
  emits: ['removeProduct', 'close'],
  data() {
    return {
      darkmode: inject("darkmode"),
      purchaseDate: null,
      quantity: 1,
      isSwap: false,
      netPrice: null,
      showPriceOptions: false,
      priceBrut: null,
      priceFees: null,
      swapStatus: false,
      inBound: false,
      storageLocation: 0,
      parentFolder: null,
      bottleStatus: null,
      formErrors: {
        purchaseDate: '',
        quantity: '',
        netPrice: '',
      },
    }
  },
  props: {
    ticker: {
      type: String,
      required: true,
    },
    bottleToUpdate: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getProduct: 'getProduct',
      folders: 'pfmFolders',
      getLists: 'getLists',
      handleUnfoldFolder: 'handleUnfoldFolder',
      getFolderBottles: 'getFolderBottles',
    }),
    isButtonDisabled() {
      return !this.purchaseDate || !this.netPrice || !this.quantity
    },
    folderList() {
      const formattedData = []

      const flattenFolderTree = (folders, level) => {
        folders.forEach((folder) => {
          formattedData.push({
            label: folder.label,
            value: folder.value,
            level: level,
          })

          if (folder.children && folder.children.length > 0) {
            flattenFolderTree(folder.children, level + 1)
          }
        })
      }

      flattenFolderTree(this.folders, 0)

      return formattedData
    },
  },
  methods: {
    ...mapActions({
      updateBottle: 'updateBottle',
      setFolderToUpdateId: 'setFolderToUpdateId',
      getBottleDetails: 'getBottleDetails',
      handleUnfoldFolder: 'handleUnfoldFolder',
      getFolder: 'getFolder',
    }),
    returnUrl(pictures) {
      if (pictures) {
        return pictures[0]
      }
    },
    removeProduct() {
      this.$emit('removeProduct')
    },
    async submitForm() {
      if (!this.purchaseDate)
        this.formErrors.purchaseDate = 'Ce champs doit être rempli'
      if (!this.netPrice)
        this.formErrors.netPrice = 'Ce champs doit être rempli'

      let swap_infos

      if (this.isSwap) {
        swap_infos = this.swapStatus
      } else {
        swap_infos = null
      }

      const payload = {
        bottle_uuid: this.bottleToUpdate.bottle_uuid,
        ticker: this.ticker,
        folder_uuid: this.parentFolder,
        sold_date: this.purchaseDate,
        price: parseInt(this.netPrice),
        currency: 'EUR',
        quantity: parseInt(this.quantity),
        buy_or_sell: this.bottleStatus,
        swap_infos: swap_infos,
        location: this.storageLocation,
        in_bond: this.inBound,
        options: {
          price_brut: this.priceBrut,
          price_fees: this.priceFees,
          description: null,
        },
      }

      await this.updateBottle(payload).then(() => {
        const payload = {
          folder_uuid: this.bottleToUpdate.folder_uuid,
          ticker: this.ticker,
        }
        this.getBottleDetails(payload)
      })
      this.$emit('close')
    },
  },
  mounted() {
    this.parentFolder = this.bottleToUpdate.folder_uuid
    this.bottleStatus = this.bottleToUpdate.buy_or_sell
    this.netPrice = this.bottleToUpdate.price
    this.quantity = this.bottleToUpdate.quantity
    this.storageLocation = this.bottleToUpdate.location ? this.bottleToUpdate.location : 0
    this.inBound = this.bottleToUpdate.in_bond
    this.swapStatus = this.bottleToUpdate.swap_infos === 1 ? true : false
    this.priceBrut = this.bottleToUpdate.opt_price_brut
    this.priceFees = this.bottleToUpdate.opt_price_fees
    this.purchaseDate = moment(
      this.bottleToUpdate.sold_date,
      'DD/MM/YYYY'
    ).format('YYYY-MM-DD')
    this.showPriceOptions =
      this.bottleToUpdate.opt_price_brut || this.bottleToUpdate.opt_price_fees
        ? true
        : false
  },
  watch: {
    purchaseDate(newValue) {
      if (newValue.length > 0) {
        this.formErrors.purchaseDate = ''
      }
    },
    quantity(newValue) {
      if (newValue.length === 0 || newValue === 0) {
        this.formErrors.quantity =
          'Ce champs doit être rempli et sa valeur minimale à 1'
      } else {
        this.formErrors.quantity = ''
      }
    },
    netPrice(newValue) {
      if (newValue.length < 1) {
        this.formErrors.netPrice = 'Ce champs doit être rempli'
      } else {
        this.formErrors.netPrice = ''
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';

.sell-or-buy {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--text-color);
}

.sell-text {
  color: var(--text-grey);
}

.sell-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: var(--text-color);
}

.green-text {
  color: $base-green-color;
}

.red-text {
  color: $alert-color;
}
.giver-text {
  color: $primary-color;
}

.taker-text {
  color: $taker-color;
}

.sell-category-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .product {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-2);
    border-radius: 12px;
    padding: 1rem;
    background-color: var(--background-color-3);

    .image {
      border: 1px solid var(--border-2);
      border-radius: 8px;
      height: 70px;
      width: 70px;
    }

    .not-found-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .product-not-found-image {
        max-height: 80%;
      }
    }

    .product-infos {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: center;
      padding-left: 1rem;

      & .product-title {
        font-size: 18px;
        color: var(--text-color);
      }

      & .product-data {
        span:first-child {
          border: 1px solid var(--border-2);
          border-radius: 8px;
          color: var(--border-2);
          padding: 4px 6px;
          font-size: 14px;
        }

        span:nth-child(2) {
          font-size: 18px;
          color: var(--subtitle-text-color);
          margin-left: 10px;
        }
      }
    }

    &-button-remove {
      background-color: var(--background-color);
      color: var(--text-color);
      font-weight: bold;
      padding: 6px;
      border: none;
      border-radius: 50px;
    }
  }

  .divider {
    border-color: var(--border-2);
  }

  .details-infos {
    display: flex;
    gap: 1rem;

    .info {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: var(--text-color);

      > div,
      > input {
        border: 1px solid var(--border-2);
        border-radius: 8px;
        height: 30px;
        padding: 5px 15px;
        color: var(--text-color);
      }

      > input {
        background-color: transparent;
      }
    }
  }

  .product-price-infos {
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    gap: 8px;

    .net-price {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > div {
        display: flex;
        border: 1px solid var(--border-2);
        border-radius: 8px;
        overflow: hidden;

        > input {
          flex-grow: 1;
          background-color: transparent;
          border: none;
          height: 30px;
          padding: 5px 15px;
          color: var(--text-color);
        }

        > div {
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-left: 1px solid var(--border-2);
          background-color: var(--background-color-3);
        }
      }
    }

    .price-checkbox {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .price-options {
      display: flex;
      gap: 1rem;

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        > input {
          background-color: transparent;
          border: 1px solid var(--border-2);
          border-radius: 8px;
          height: 30px;
          padding: 5px 15px;
          color: var(--text-color);
        }
      }
    }
  }

  .storage-infos,
  .parent-folder-infos {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--text-color);

    > select {
      background-color: transparent;
      height: 40px;
      padding: 5px 15px;
      color: var(--text-color);
      border: 1px solid var(--border-2);
      border-radius: 8px;
    }
  }

  .submit-button {
    max-width: fit-content;
    margin: auto;
    padding: 10px 1rem;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    background-color: $primary-color;

    &:hover {
      background-color: var(--bg-primary-var-2);
    }
  }

  .error-message {
    height: 10px;
    font-size: 0.9rem;
    color: $alert-color;
  }
}
</style>
