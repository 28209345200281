<template>
  <div class="portfolio-container">
    <div class="button-container">
      <button class="button-metrics">
        <img
          class="metrics-icon themed-img"
          src="@/assets/svg/v2/portfolio/metrics.svg"
          alt="metrics icon"
        />
        Metrics
      </button>
      <button class="button-portfolio">
        <img
          class="portfolio-icon themed-img"
          src="@/assets/svg/v2/portfolio/portfolioWhite.svg"
          alt="portfolio icon"
        />
        Portfolio
      </button>
    </div>

    <Statistics />

    <div class="header-bottles">
      <div class="text-add">
        <h2 class="page-subtitle">{{ $t('portfolio.my_bottles') }}</h2>
        <AddButton v-if="folders" />
      </div>
      <div class="buttons">
        <button class="button action-light" @click="handleUnfoldAll">
          <img
            v-if="isAllFoldersUnfold"
            :src="require('@/assets/svg/v2/portfolio/arrowFolderToBottom.svg')"
            alt="Arrow icon to bottom"
            class="bottom themed-img"
          />
          <img
            v-else
            :src="require('@/assets/svg/v2/portfolio/arrowFolderToRight.svg')"
            alt="Arrow icon to right"
            class="right themed-img"
          />
          {{
            isAllFoldersUnfold
              ? $t('portfolio.foldAll')
              : $t('portfolio.unfoldAll')
          }}
        </button>
        <ImportButton @refresh="handleUnfoldAll('refresh')" />
        <SettingsButton />
      </div>
    </div>
    <ManagementTable
      v-if="folders && pfmSettings"
      :folders="folders"
      :isAllFoldersUnfold="isAllFoldersUnfold"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ManagementTable from '@/components/modules/portfolio/managementTable.vue'
import AddButton from '@/components/modules/portfolio/addButton.vue'
import SettingsButton from '@/components/modules/portfolio/settingsButton.vue'
import ImportButton from '@/components/modules/portfolio/importButton.vue'
import Statistics from '@/components/modules/portfolio/statistics.vue'

export default {
  name: 'portfolioView',
  data() {
    return {
      showQuantityZero: false,
      isAllFoldersUnfold: false,
    }
  },
  components: {
    Statistics,
    SettingsButton,
    AddButton,
    ManagementTable,
    ImportButton,
  },
  mounted() {
    this.getFolders();
    this.getSettings();
    this.getSubscriptions();
  },
  computed: {
    ...mapGetters({
      folders: 'pfmFolders',
      pfmSettings: 'pfmSettings',
    }),
  },
  methods: {
    ...mapActions({
      getFolders: 'getFolders',
      getSettings: 'getSettings',
      getSubscriptions: 'getSubscriptions',
    }),
    handleUnfoldAll(isRefresh) {
      if (isRefresh === 'refresh') {
        if (!this.isAllFoldersUnfold) {
          this.isAllFoldersUnfold = true
        } else {
          this.isAllFoldersUnfold = false
          setTimeout(() => {
            this.isAllFoldersUnfold = true
          }, 300)
        }
      } else {
        this.isAllFoldersUnfold = !this.isAllFoldersUnfold
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';
$border-color: rgba(255, 255, 255, 0.3);

.portfolio-container {
  padding: 2rem 4rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button-container {
  display: none;
}

.bottom {
  max-height: 5px !important;
}

.right {
  max-width: 5.5px !important;
}

.button {
  border: 1px solid $primary-color;
  border-radius: 8px;
  padding: 5px 10px;
  background: $primary-color;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-right: 10px;
  height: 32px;
  width: 115px;
}

.not-available-tel {
  display: none;
}

.header-bottles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.page-subtitle {
  color: var(--text-color);
}

.text-add {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -5px;
  
  button {
    color: var(--text-color);
  }

  div {
    display: flex;
    flex-direction: row;
  }
}

.search-bottles {
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--border);
  background: var(--background-color-2);
  padding: 5px 12px;
  margin-top: 15px;

  p {
    color: var(--subtitle-text-color);
    font-size: 15px;
    font-weight: 500;
    margin-left: 5px;
  }
}

@media screen and (max-width: 500px) {
  .portfolio-container {
    display: flex;
    padding: 20px 15px 20px 15px;
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
  }

  // .button-container {
  //   display: flex;
  //   width: 100%;
  //   margin-bottom: 20px;
  //   box-sizing: border-box;
  //   height: 48px;
  //   padding: 0;
  //   border-radius: 12px;
  //   background-color: var(--background-color-13);
  //   border: 1px solid var(--background-color-11);

  //   .button-metrics,
  //   .button-portfolio {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     flex: 1;
  //     gap: 8px;
  //     font-size: 20px;
  //   }

  //   .button-metrics {
  //     margin: 5px;
  //     border-radius: 10px;
  //     border: none;
  //     background-color: var(--background-color-11);
  //     color: var(--text-color);
  //   }

  //   .button-portfolio {
  //     border: none;
  //     background-color: var(--background-color-11);
  //     color: var(--subtitle-2-text-color);
  //     cursor: not-allowed;

  //     img {
  //       opacity: 0.5;
  //     }
  //   }
  //   .button-portfolio:hover {
  //     cursor: not-allowed;
  //   }
  // }

  .search-bottles {
    flex-direction: column;
  }

  .header-bottles {
    display: none;
  }
}
</style>
