<template>
  <div
    class="phone-disabled"
    v-loading="isLoading"
    element-loading-background="rgba(0, 0, 0, 0.4)"
  >
    <CommonConfirm
      :isVisible="showConfirm"
      :message="$t('portfolio.are_you_sure_delete_folder')"
      :cancelButtonText="$t('commons.cancel')"
      :validateButtonText="$t('commons.confirm')"
      ref="confirm"
    />

    <Transition name="action-headband-scale">
      <div v-if="checkedBottleTickers.length > 0" class="action-headband action-light">
        {{ checkedBottleTickers.length }} {{ $t('portfolio.selected_bottles') }}

        <div>
          <button @click="handleMoveModal">
            <img
              :src="require('@/assets/svg/v2/portfolio/moveButton.svg')"
              class="themed-img"
              alt="Move icon"
            />
            {{ $t('portfolio.move') }}
          </button>
          <button @click="deleteSelectedReferences">
            <img
              :src="require('@/assets/svg/v2/portfolio/trashButton.svg')"
              class="themed-img"
              alt="Trash icon"
            />
            {{ $t('portfolio.delete') }}
          </button>
        </div>

        <CommonModal
          v-if="moveModal"
          size="small"
          modal-title="Déplacer les bouteilles sélectionnées"
          @close="handleMoveModal"
        >
          <MoveModalContent
            :checkedBottleTickers="checkedBottleTickers"
            :folders="folders"
            :bottleList="bottleList"
            @move="updateFolderAfterMove"
          ></MoveModalContent>
        </CommonModal>
      </div>
    </Transition>

    <div class="table-container">
      <CommonModal
        v-if="renameFolderModal"
        size="small"
        :modal-title="$t('portfolio.rename_folder')"
        @close="closeRenameFolderModal"
      >
        <renameFolderModalContent
          :folder="selectedFolderToRename"
          @close="closeRenameFolderModal"
        ></renameFolderModalContent>
      </CommonModal>
      <table>
        <thead>
          <tr>
            <th
              v-for="(header, h) in tableHeaders"
              :key="header"
              class="th-contain-text"
              :style="'min-width:' + header.size + 'px'"
              @mousedown="resizeColumnStart($event, h)"
              @mousemove="resizeColumn"
              @mouseup="resizeColumnEnd"
            >
              <div class="no-wrap sort">
                <!-- <CommonCheckbox v-if="h === 1" v-model="allBottlesChecked" size="15" @click="handleBottlesAllChecked" /> -->
                <span>{{ header.name }}</span>
                <div v-if="h > 0">
                  <img
                    @click.stop="sortBy(0, 1, h)"
                    v-if="
                      sortType === null ||
                      sortType === 'desc' ||
                      sortKey !== tableHeaders[h].name
                    "
                    src="@/assets/svg/v2/portfolio/sort_top_grey.svg"
                    class="themed-img"
                    alt="sort off"
                    height="6"
                  />
                  <img
                    @click.stop="sortBy(2, null, h)"
                    v-if="
                      sortType === 'asc' && sortKey === tableHeaders[h].name
                    "
                    src="@/assets/svg/v2/portfolio/sort_top_white.svg"
                    class="themed-img"
                    alt="sort on asc"
                    height="6"
                  />
                  <img
                    @click.stop="sortBy(1, null, h)"
                    v-if="
                      sortType === 'desc' && sortKey === tableHeaders[h].name
                    "
                    src="@/assets/svg/v2/portfolio/sort_bottom_white.svg"
                    class="themed-img"
                    alt="sort on desc"
                    height="6"
                  />
                  <img
                    @click.stop="sortBy(0, 2, h)"
                    v-if="
                      sortType === null ||
                      sortType === 'asc' ||
                      sortKey !== tableHeaders[h].name
                    "
                    src="@/assets/svg/v2/portfolio/sort_bottom_grey.svg"
                    class="themed-img"
                    alt="sort off"
                    height="6"
                  />
                </div>
              </div>
            </th>
            <th class="options-header" />
          </tr>
        </thead>

        <tbody>
          <template v-for="(item, rowNumber) in tableRows" :key="item">
            <tr
              v-if="
                (enabledRow(item) && item.type !== 'bottle') ||
                (pfmShowQuantityZero &&
                  unfoldedFolderIds.includes(item.folder_uuid)) ||
                (item.quantity > 0 &&
                  unfoldedFolderIds.includes(item.folder_uuid))
              "
              :draggable="item.type !== 'folder'"
              @dragstart="startDrag($event, item)"
              @drop="onDrop($event, item)"
              @dragenter.prevent
              @dragover.prevent
            >
              <td :style="getItemStyle(item)">
                <div
                  v-if="item.type === 'folder' && isDisplayed(item)"
                  class="cell-content cell-content-folder"
                  @click="handleFolderClicked(item.value)"
                >
                  <img
                    v-if="
                      unfoldedFolderIds.includes(item.value) && item.level !== 0
                    "
                    :src="
                      require('@/assets/svg/v2/portfolio/arrowFolderToBottom.svg')
                    "
                    alt="Arrow icon to bottom"
                    class="arrow-icon bottom themed-img"
                  />
                  <img
                    v-if="
                      !unfoldedFolderIds.includes(item.value) &&
                      item.level !== 0
                    "
                    :src="
                      require('@/assets/svg/v2/portfolio/arrowFolderToRight.svg')
                    "
                    alt="Arrow icon to top"
                    class="arrow-icon right themed-img"
                  />
                  <img
                    :src="
                      require('@/assets/svg/v2/portfolio/portfolioFolder.svg')
                    "
                    alt="Folder Icon"
                  />
                  <p v-if="item.type === 'folder'" class="folder-label no-wrap">
                    {{ item.label }}
                  </p>
                  <el-tooltip
                    :content="item.value"
                    placement="bottom"
                    effect="dark"
                    trigger="hover"
                    :enterable="true"
                  >
                    <img
                      :src="require('@/assets/svg/v2/portfolio/infobulle.svg')"
                      class="themed-img"
                      alt="Info Icon"
                      @click="folderIDCopied($event, item.value)"
                    />
                  </el-tooltip>
                </div>

                <div
                  v-if="item.type === 'bottle'"
                  class="cell-content no-wrap"
                  @click="openDetailsOnClick(item)"
                >
                  <img
                    :src="
                      require('@/assets/svg/v2/portfolio/portfolioLiquor.svg')
                    "
                    class="themed-img"
                    alt="Liquor Icon"
                  />
                  <p class="cell-name">
                    {{ item.ticker }}

                    <el-tooltip
                      class="tooltip"
                      effect="dark"
                      :content="returnIfDelayedPrice(item.infos.spirit_type).labelKeys"
                      placement="top"
                    >
                      <span v-if="returnIfDelayedPrice(item.infos.spirit_type).isDelayed" class="delayed-price">d</span>
                    </el-tooltip>
                    
                  </p>
                </div>
              </td>

              <td
                :style="'width:' + tableHeaders[1].size + 'px'"
                class="cell-name no-wrap td-contain-text"
                @click="openDetailsOnClick(item)"
              >
                <CommonCheckbox
                  v-if="item.type === 'bottle'"
                  v-model="checkedBottleTickersMap[item.bottle_uuid]"
                  size="15"
                  @click.stop="handleBottleChecked($event, item)"
                />
                {{ item.title }}
              </td>

              <td
                v-if="rowNumber > 0"
                class="td-contain-text"
                :class="{ 'folder-label': item.type === 'folder' }"
                :style="'min-width:' + tableHeaders[2].size + 'px'"
                @click="openDetailsOnClick(item)"
              >
                {{ item.quantity }}
              </td>
              <td
                class="td-contain-text"
                :style="'min-width:' + tableHeaders[3].size + 'px'"
                @click="openDetailsOnClick(item)"
              >
                {{ item.type === 'folder' ? ' ' : item.last_price }}
              </td>

              <template
                v-for="(column, c) in tableAdditionalColumns"
                :key="column"
              >
                <td
                  v-if="rowNumber > 0"
                  class="td-contain-text"
                  :class="{
                    'folder-label': item.type === 'folder',
                    'negative-number': parseFloat(item[column]) < 0,
                  }"
                  :style="'max-width:' + returnComlumnSize(c) + 'px'"
                >
                  {{ item[column] || '-' }}
                </td>
              </template>
              <td class="options-column">
                <div>
                  <img
                    v-if="
                      (item.type === 'folder' || item.type === 'bottle') &&
                      item.level != 0
                    "
                    :src="require('@/assets/svg/v2/portfolio/optionsIcon.svg')"
                    class="themed-img"
                    alt="Options icon"
                    @click="clickOnOptionsBottle(item)"
                  />
                  <CommonTooltip
                    v-if="
                      clickedOptionItemValueOrTicker === item.value &&
                      openOptionsBottle === true
                    "
                    @close="closeTooltip"
                    position="left-folder"
                  >
                    <FolderOptionsTooltip
                      :folder="item"
                      @delete-folder="
                        deleteSelectedFolder(clickedOptionItemValueOrTicker)
                      "
                      @rename-folder="renameSelectedFolder"
                      @move-folder="moveSelectedFolder"
                    />
                  </CommonTooltip>
                  <CommonTooltip
                    v-if="
                      clickedOptionItemValueOrTicker === item.bottle_uuid &&
                      openOptionsBottle === true
                    "
                    @close="closeTooltip"
                    position="left"
                  >
                    <BottleOptionsTooltip
                      @openDetails="handleOpenDetails"
                      @toProductPage="getProductPageLink"
                      @deleteBottle="deleteBottle"
                    />
                  </CommonTooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>

  <CommonModal
    v-if="moveModalFolder"
    @close="closeTooltip"
    :modal-title="$t('portfolio.move_in_another_folder')"
    size="small"
  >
    <MoveModalFolder
      :origin="moveFolderOrigin"
      @close="moveModalFolder = false"
    />
  </CommonModal>

  <CommonModal
    v-if="
      isDetailModalOpened &&
      getBottlesCount.count_buy + getBottlesCount.count_sell
    "
    size="x-large"
    :modal-title="''"
    @close="closeModal"
  >
    <BottleModalContent
      :pfmBottleDetailsSELL="pfmBottleDetailsSELL"
      :pfmBottleDetailsBUY="pfmBottleDetailsBUY"
      :getProduct="getProduct"
      :selectedItem="selectedItem"
      @delete="deleteDetailsReferences"
      @move="moveDetailsReferences"
      @refresh="refreshPortfolio"
    >
    </BottleModalContent>
  </CommonModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import CommonCheckbox from '@/components/commons/checkbox.vue'
import CommonTooltip from '@/components/commons/tooltip.vue'
import FolderOptionsTooltip from '@/components/modules/portfolio/folderOptionsTooltip.vue'
import BottleOptionsTooltip from '@/components/modules/portfolio/bottleOptionsTooltip.vue'
import BottleModalContent from '@/components/modules/portfolio/bottleModalContent.vue'
import renameFolderModalContent from '@/components/modules/portfolio/renameFolderModalContent.vue'
import MoveModalContent from '@/components/modules/portfolio/moveModalContent.vue'
import MoveModalFolder from '@/components/modules/portfolio/moveModalFolder.vue'
import CommonConfirm from '@/components/commons/confirm.vue'
import CommonModal from '@/components/commons/modal.vue'
import { ElMessage } from 'element-plus'
import { filter, findIndex, find } from 'lodash'

export default {
  name: 'managementTable',
  components: {
    CommonConfirm,
    BottleOptionsTooltip,
    FolderOptionsTooltip,
    CommonTooltip,
    CommonCheckbox,
    CommonModal,
    BottleModalContent,
    MoveModalContent,
    MoveModalFolder,
    renameFolderModalContent,
  },
  props: {
    isAllFoldersUnfold: {
      type: Boolean,
      required: true,
    },
    folders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      bottleList: [],
      numberBottleSelected: 0,
      unfoldedFolderIds: [],
      checkedBottleTickers: [],
      clickedOptionItemValueOrTicker: null,
      allBottlesChecked: false,
      isLoading: false,
      showConfirm: false,
      isDetailModalOpened: false,
      selectedItem: null,
      openOptionsBottle: false,
      moveModal: false,
      moveModalFolder: false,
      moveFolderOrigin: null,
      renameFolderModal: false,
      selectedFolderToRename: null,
      checkedBottleTickersMap: [],

      resizingColumnIndex: null,
      resizingStartX: null,
      resizingStartWidth: null,
      fixedHeaders: [
        { name: 'Portfolio', size: '', value: '' },
        { name: 'Name', size: '', value: 'title' },
        { name: 'Quantity', size: '', value: 'quantity' },
        { name: 'Last price', size: '', value: 'last_price' },
      ],

      needSettingsUpdate: false,
      settingsUpdatePending: false,

      sortType: null,
      sortKey: null,
    }
  },
  computed: {
    ...mapGetters({
      folder: 'pfmFolder',
      pfmShowQuantityZero: 'pfmShowQuantityZero',
      folderToUpdateId: 'pfmFolderToUpdateId',
      pfmSettings: 'pfmSettings',
      pfmAllBottles: 'pfmAllBottles',
      getLists: 'getLists',
      pfmBottleDetailsBUY: 'pfmBottleDetailsBUY',
      pfmBottleDetailsSELL: 'pfmBottleDetailsSELL',
      getProduct: 'getProduct',
      getBottlesCount: 'pfmGetBottlesCount',
      pfmGetBottleList: 'pfmGetBottleList',
      pfmStatistics: 'pfmStatistics',
      userSubscriptions: 'getSubscriptions',
      registerStep: 'registerStep'
    }),
    tableHeaders() {
      const optionalHeaders = this.pfmSettings.columns_options.map((option) => {
        const correspondingItem = this.getLists.table_columns_options.find(
          (item) => item.value === option
        )
        const correspondingLayout = find(
          this.pfmSettings.columns_layout,
          (layout) => {
            if (layout.name === 'Name') {
              this.fixedHeaders[1].size = layout.size
            } else if (layout.name === 'Quantity') {
              this.fixedHeaders[2].size = layout.size
            } else if (layout.name === 'Last price') {
              this.fixedHeaders[3].size = layout.size
            }

            return layout.name === correspondingItem.label
          }
        )

        return {
          name: correspondingItem.label,
          size: correspondingLayout ? correspondingLayout.size : '',
          value: correspondingItem.value,
        }
      })

      return [
        ...this.fixedHeaders,
        ...optionalHeaders.filter((o) => {
          return (
            o.name !== 'Brand' &&
            o.name !== 'Sub Asset Class' &&
            o.name !== 'Volatility'
          )
        }),
      ]
    },
    tableAdditionalColumns() {
      return this.pfmSettings.columns_options.filter((o) => {
        return o !== 'brand' && o !== 'sub_asset_class' && o !== 'volatility'
      })
    },
    tableRows() {
      const folders = this.folders
      const bottleList = this.bottleList
      const formattedData = []

      const flattenFolderList = (folders, level = 0) => {
        folders.forEach((folder) => {
          const folderInfos = {
            label: folder.label,
            value: folder.value,
            parent_uuid: folder.parent_uuid,
            level: level,
            quantity: 0,
            current_value: 0,
            holding_length: 0,
            weight: 0,
            running_pnl: 0,
            running_return: 0,
            on_position: 0,
            realized_pnl: 0,
            running_y_return: 0,
            realized_return: 0,
            total_bought: 0,
            avg_diff_today_folder: 0,
            wabd: ' ',
            isbn: ' ',
            category: ' ',
            distillery: ' ',
            vintage: ' ',
            age: ' ',
            country: ' ',
            sc: ' ',
            bottle_size: ' ',
            size: ' ',
            bottler: ' ',
            type: 'folder',
          }

          function getAllChildren(obj) {
            let allChildren = []
            allChildren.push(obj.value)

            if (obj.children && Array.isArray(obj.children)) {
              obj.children.forEach((child) => {
                allChildren.push(child.value)

                if (child.children && Array.isArray(child.children)) {
                  allChildren = allChildren.concat(getAllChildren(child))
                }
              })
            }

            return allChildren
          }

          const allChildren = getAllChildren(folder)
          const bottlesInFolder = bottleList.filter((o) => {
            return allChildren.includes(o.folder_uuid)
          })

          const bottlesInCurrentFolder = bottleList.filter((bottle) => {
            return bottle.folder_uuid === folderInfos.value
          })

          bottlesInFolder.forEach((bottle) => {
            folderInfos.current_value += bottle.current_value
            folderInfos.holding_length +=
              bottle.holding_length === '-' ? 0 : Number(bottle.holding_length)
            folderInfos.running_pnl +=
              bottle.running_pnl !== '-'
                ? Number(parseFloat(bottle.running_pnl).toFixed(0))
                : 0
            folderInfos.on_position += bottle.on_position
            folderInfos.realized_pnl +=
              bottle.realized_pnl !== '-' ? bottle.realized_pnl : 0
            folderInfos.avg_diff_today_folder += bottle.diff_today_to_avg
            folderInfos.total_bought += bottle.sum_bought_bottles_sold
            folderInfos.quantity += bottle.quantity

            if (
              bottle.running_y_return === 'Infinity %' ||
              bottle.diff_today_to_avg === 0
            ) {
              bottle.running_y_return = ' '
            }
          })

          if (bottlesInFolder.length > 0) {
            folderInfos.holding_length = Number(
              parseFloat(
                folderInfos.holding_length / bottlesInFolder.length
              ).toFixed(0)
            )
          } else {
            folderInfos.holding_length = ' '
          }

          if (
            folderInfos.realized_pnl === 0 ||
            folderInfos.realized_pnl === '-'
          ) {
            folderInfos.realized_pnl = ' '
          }

          folderInfos.realized_return =
            parseFloat(
              (folderInfos.realized_pnl / folderInfos.total_bought) * 100
            ).toFixed(1) + ' %'

          if (folderInfos.realized_return == 'NaN %') {
            folderInfos.realized_return = ' '
          }

          folderInfos.running_y_return =
            parseFloat(
              this.pfmStatistics?.yield *
                (1 /
                  (folderInfos.avg_diff_today_folder /
                    bottlesInFolder.length /
                    365))
            ).toFixed(1) + ' %'

          if (
            folderInfos.running_y_return == 'NaN %' ||
            folderInfos.running_y_return == 'Infinity %'
          ) {
            folderInfos.running_y_return = ' '
          }

          if (folderInfos.on_position) {
            folderInfos.running_return =
              parseFloat(
                (folderInfos.running_pnl / folderInfos.on_position) * 100
              ).toFixed(1) + ' %'
          } else {
            folderInfos.running_return = ' '
          }

          if (folderInfos.current_value && this.pfmStatistics?.aum) {
            folderInfos.weight =
              parseFloat(
                (folderInfos.current_value / this.pfmStatistics?.aum) * 100
              ).toFixed(1) + ' %'
          } else {
            folderInfos.weight = ' '
          }

          bottlesInFolder.forEach((bottle) => {
            bottle.weight =
              parseFloat(
                ((bottle.last_price * bottle.quantity) /
                  folderInfos.current_value) *
                  100
              ).toFixed(1) + ' %'
            bottle.weight = bottle.weight.replace('.00', '')

            if (bottle.weight === '0 %') {
              bottle.weight = ' '
            }
          })

          folderInfos.weight = folderInfos.weight.replace('.00', '')

          formattedData.push(folderInfos)

          bottlesInCurrentFolder.forEach((bottle) => {
            formattedData.push({
              ...bottle,
              level: level + 1,
              type: 'bottle',
            })
          })

          if (folder.children && folder.children.length > 0) {
            flattenFolderList(folder.children, level + 1)
          }
        })
      }

      flattenFolderList(folders, 0)

      return formattedData
    },
  },
  methods: {
    ...mapActions({
      getFolder: 'getFolder',
      setFolderToUpdateId: 'setFolderToUpdateId',
      deleteBottles: 'deleteBottles',
      deleteFolder: 'deleteFolder',
      getBottleDetails: 'getBottleDetails',
      product: 'product',
      updateBottlesFolder: 'updateBottlesFolder',
      updateBottle: 'updateBottle',
      getAllFolders: 'getAllFolders',
      getFolders: 'getFolders',
      getStatistics: 'getStatistics',
      updateSettings: 'updateSettings',
    }),
    returnIfDelayedPrice(spiritType) {
      const findSpiritCategory = find(this.userSubscriptions, { subscription_spirit_code: spiritType });
      const resultSpiritCategory = {
        labelKeys : '',
        isDelayed: false
      }

      if (findSpiritCategory) {
        if (!findSpiritCategory.subscription_state && this.registerStep < 3) {
          resultSpiritCategory.isDelayed = true;
          resultSpiritCategory.labelKeys = `${this.$t('product_page.price_history.delay')} 15 ${this.$t('product_page.price_history.delay_2')}`;
        } else if (!findSpiritCategory.subscription_state && this.registerStep >= 3) {
          resultSpiritCategory.isDelayed = true;
          resultSpiritCategory.labelKeys = `${this.$t('product_page.price_history.delay')} 12 ${this.$t('product_page.price_history.delay_2')}`;
        }
      }

      return resultSpiritCategory;
    },
    handleMoveModal() {
      if (this.moveModal === false) {
        this.moveModal = true
      } else {
        this.moveModal = false
      }
    },
    isDisplayed(item) {
      if (item.level === 0) {
        return true
      } else if (this.unfoldedFolderIds.includes(item.parent_uuid)) {
        return true
      }
      return false
    },
    enabledRow(item) {
      if (
        !this.unfoldedFolderIds.includes(item.parent_uuid) &&
        item.type === 'folder' &&
        item.level !== 0
      ) {
        return false
      }
      return true
    },
    renameSelectedFolder(folder) {
      this.renameFolderModal = true
      this.selectedFolderToRename = folder
    },
    moveSelectedFolder(folder) {
      this.moveFolderOrigin = folder
      this.moveModalFolder = true
    },
    folderIDCopied(event, folderID) {
      navigator.clipboard.writeText(folderID)
      var elementsAvecClasseElPopper =
        document.getElementsByClassName('el-popper')

      var elementAvecClasseElPopper =
        elementsAvecClasseElPopper[elementsAvecClasseElPopper.length - 1]

      var spanInterne = elementAvecClasseElPopper.querySelector('span')

      if (spanInterne) {
        spanInterne.textContent = this.$t('portfolio.copied_id') + ' ✅'
      }
      event.stopPropagation()
    },
    async updateFolderAfterMove(newFolderID) {
      setTimeout(async () => {
        this.allBottlesChecked = false
        this.numberBottleSelected = 0
        this.checkedBottleTickersMap = []
        this.moveModal = false
        this.isLoading = true
        const uniqueFolderUuids = this.checkedBottleTickers
          .map((bottle) => bottle.folder_uuid)
          .filter((value, index, self) => self.indexOf(value) === index)

        await this.getFolderBottles(newFolderID)

        uniqueFolderUuids.forEach(async (folderId) => {
          await this.getFolderBottles(folderId)
          this.handleUnfoldFolder(folderId)
        })

        this.handleUnfoldFolder(newFolderID)
        this.isLoading = false
        this.checkedBottleTickers = []
      }, 500)
    },
    async deleteBottle() {
      if (this.selectedItem) {
        const filterFolderChecked = filter(this.pfmAllBottles, (bottle) => {
          return (
            bottle.folder_uuid === this.selectedItem.folder_uuid &&
            bottle.isbn === this.selectedItem.isbn
          )
        })

        const payload = {
          bottles: filterFolderChecked,
        }

        this.isLoading = true

        await this.deleteBottles(payload)
          .then(() => {
            this.getFolderBottles(this.selectedItem.folder_uuid)
            this.handleUnfoldFolder(this.selectedItem.folder_uuid)

            const payload = {
              currency: 'EUR',
              period: 'a',
            }

            this.getStatistics(payload)
          })
          .finally(() => {
            this.checkedBottleTickersMap = []
            this.isLoading = false
          })
      }
    },
    startDrag(event, item) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('bottleTicker', item.ticker)
      event.dataTransfer.setData('folderID', item.folder_uuid)
    },
    async onDrop(event, item) {
      const bottleTicker = event.dataTransfer.getData('bottleTicker')
      const folderID = event.dataTransfer.getData('folderID')

      const bottlesDragIds = []

      const folder = await this.getFolder(folderID)

      folder.bottlesMap.forEach((bottle) => {
        if (bottle.ticker === bottleTicker) {
          bottlesDragIds.push(bottle.bottle_uuid)
        }
      })
      let targetFolderID =
        item.type === 'bottle' ? item.folder_uuid : item.value

      this.isLoading = true
      const bottles = bottlesDragIds.map((bottleID) => ({
        parent_folder_uuid: targetFolderID,
        bottle_uuid: bottleID,
      }))

      const payload = {
        current_folder: folderID,
        new_parent_folder: targetFolderID,
        bottles: bottles,
      }

      if (payload.bottles.length > 0) {
        await this.updateBottlesFolder(payload).then(() => {
          if (item.folder_uuid || item.value) {
            const selectFolderUUID = item.folder_uuid
              ? item.folder_uuid
              : item.value
            this.handleUnfoldFolder(folderID)
            this.handleUnfoldFolder(selectFolderUUID)
            this.checkedBottleTickersMap = []
          }

          this.isLoading = false
        })
      }
    },
    getProductPageLink() {
      this.$router.push(`/product/${this.selectedItem.isbn}`)
    },
    selectedItemValueOrTicker(item) {
      this.selectedItem = item
      if (item.type === 'folder') {
        this.clickedOptionItemValueOrTicker = item.value
      } else {
        this.clickedOptionItemValueOrTicker = item.bottle_uuid

        const payload = {
          folder_uuid: this.selectedItem.folder_uuid,
          ticker: this.selectedItem.ticker,
          isbn: this.selectedItem.isbn,
        }
        this.getBottleDetails(payload)
        this.product(payload)
      }
    },
    clickOnOptionsBottle(item) {
      this.openOptionsBottle = true
      this.selectedItem = item
      if (item.type === 'folder') {
        this.clickedOptionItemValueOrTicker = item.value
      } else {
        this.clickedOptionItemValueOrTicker = item.bottle_uuid

        const payload = {
          folder_uuid: this.selectedItem.folder_uuid,
          ticker: this.selectedItem.ticker,
          isbn: this.selectedItem.isbn,
        }
        this.getBottleDetails(payload)
        this.product(payload)
      }
    },
    openDetailsOnClick(item) {
      if (this.numberBottleSelected === 0) {
        this.selectedItemValueOrTicker(item)
        if (item.type === 'bottle') {
          this.handleOpenDetails()
        }
      }
    },
    closeModal() {
      this.isDetailModalOpened = false
      this.clickedOptionItemValueOrTicker = null
    },
    getItemStyle(item) {
      return { 'padding-left': `${item.level * 20 + 20}px` }
    },
    removeIdFromUnfoldedFolderIds(folderId) {
      const index = this.unfoldedFolderIds.indexOf(folderId)
      if (index > -1) {
        this.unfoldedFolderIds.splice(index, 1)
      }
    },
    async getFolderBottles(folderId) {
      await this.getFolder(folderId)

      const bottleListWithoutThoseInFolder = this.bottleList.filter(
        (bottle) => bottle.folder_uuid !== folderId
      )

      this.bottleList = [
        ...bottleListWithoutThoseInFolder,
        ...this.folder.bottles,
      ]
    },
    removeFolderBottlesFromList(folderId) {
      this.bottleList = this.bottleList.filter(
        (bottle) => bottle.folder_uuid !== folderId
      )
    },
    handleOpenDetails() {
      if (this.isDetailModalOpened == false) {
        this.isDetailModalOpened = true
      }
    },
    pushFolderChildrenToUnfoldedFolderIds(folder) {
      this.unfoldedFolderIds.push(folder.value)
      if (folder.children && folder.children.length > 0) {
        folder.children.forEach((childFolder) => {
          this.pushFolderChildrenToUnfoldedFolderIds(childFolder)
        })
      }
    },
    handleFolderClicked(folderId) {
      if (this.unfoldedFolderIds.includes(folderId)) {
        if (folderId !== this.folders[0].value) {
          this.handleFoldFolder(folderId)
        }
      } else {
        this.handleUnfoldFolder(folderId)
      }
    },
    async handleUnfoldFolder(folderId) {
      this.isLoading = true
      await this.getFolderBottles(folderId)
      this.unfoldedFolderIds.push(folderId)
      this.isLoading = false
    },
    handleFoldFolder(folderId) {
      this.removeIdFromUnfoldedFolderIds(folderId)

      const findFolder = (folders, id) => {
        for (let folder of folders) {
          if (folder.value === id) {
            return folder
          }
          if (folder.children) {
            const found = findFolder(folder.children, id)
            if (found) {
              return found
            }
          }
        }
        return null
      }

      const foldSubFolderTree = (folderId) => {
        const referenceFolder = findFolder(this.folders, folderId)
        if (
          referenceFolder &&
          referenceFolder.children &&
          referenceFolder.children.length > 0
        ) {
          referenceFolder.children.forEach((childFolder) => {
            if (this.unfoldedFolderIds.includes(childFolder.value)) {
              this.handleFoldFolder(childFolder.value)
            }
          })
        }
      }

      foldSubFolderTree(folderId)
    },
    async handleBottleChecked(event, item) {
      const bottleIndex = this.checkedBottleTickers.findIndex(
        (bottle) => bottle.bottle_uuid === item.bottle_uuid
      )

      if (this.folder.folder !== item.folder_uuid) {
        let allBottlesInFolder = await this.getFolder(item.folder_uuid)
        this.bottleCheckedDispatch(bottleIndex, allBottlesInFolder, item)
      } else {
        this.bottleCheckedDispatch(bottleIndex, this.folder, item)
      }

      event.stopPropagation()
    },
    bottleCheckedDispatch(bottleIndex, allBottlesInFolder, item) {
      const allBottlesSelected = allBottlesInFolder.bottlesMap.filter(
        (bottle) => bottle.isbn === item.isbn
      )

      if (bottleIndex === -1) {
        this.numberBottleSelected++
        allBottlesSelected.forEach((bottle) => {
          this.checkedBottleTickers.push({
            bottle_uuid: bottle.bottle_uuid,
            folder_uuid: bottle.folder_uuid,
            isbn: bottle.isbn,
          })
        })
      } else {
        this.numberBottleSelected--
        const filteredBottlesSameISBN = filter(
          this.checkedBottleTickers,
          (o) => {
            return o.isbn === item.isbn && o.folder_uuid === item.folder_uuid
          }
        )

        filteredBottlesSameISBN.forEach((sameISBN) => {
          const findIndexInCheckedBottles = findIndex(
            this.checkedBottleTickers,
            (o) => {
              return o.bottle_uuid === sameISBN.bottle_uuid
            }
          )

          if (findIndexInCheckedBottles !== -1) {
            this.checkedBottleTickers.splice(findIndexInCheckedBottles, 1)
          }
        })
      }
    },
    handleBottlesAllChecked() {
      this.numberBottleSelected = 0
      if (this.allBottlesChecked === false) {
        this.checkedBottleTickers = []
        this.checkedBottleTickersMap = []
      } else {
        this.unfoldedFolderIds.forEach((folderId) => {
          const filterFolderChecked = filter(this.folder, (bottle) => {
            return bottle.folder_uuid === folderId
          })

          if (filterFolderChecked.length === 0) {
            const filterFolderChecked = filter(
              this.folder.bottlesMap,
              (bottle) => {
                return bottle.folder_uuid === folderId
              }
            )

            filterFolderChecked.forEach((checkBottle) => {
              this.numberBottleSelected++
              this.checkedBottleTickersMap[checkBottle.bottle_uuid] = true
              this.checkedBottleTickers.push({
                bottle_uuid: checkBottle.bottle_uuid,
                folder_uuid: checkBottle.folder_uuid,
                isbn: checkBottle.isbn,
              })
            })
          } else {
            filterFolderChecked.forEach((checkBottle) => {
              this.numberBottleSelected++
              this.checkedBottleTickersMap[checkBottle.bottle_uuid] = true
              this.checkedBottleTickers.push({
                bottle_uuid: checkBottle.bottle_uuid,
                folder_uuid: checkBottle.folder_uuid,
                isbn: checkBottle.isbn,
              })
            })
          }
        })
      }
    },
    async deleteDetailsReferences(checkBottles) {
      this.isDetailModalOpened = false
      checkBottles.forEach((item) => {
        this.checkedBottleTickers.push(item)
      })
      await this.deleteSelectedReferences()
    },

    async moveDetailsReferences(checkBottles) {
      this.isDetailModalOpened = false
      checkBottles.forEach((item) => {
        this.checkedBottleTickers.push(item)
      })
      this.moveModal = true
    },

    async deleteSelectedReferences() {
      const bottles = this.checkedBottleTickers.map(function (bottle) {
        return {
          bottle_uuid: bottle.bottle_uuid,
        }
      })

      await this.deleteBottles({
        bottles: bottles,
      }).then(async () => {
        this.isLoading = true

        const payload = {
          currency: 'EUR',
          period: 'a',
        }

        this.bottleList = this.bottleList.filter((bottle) => {
          return !this.checkedBottleTickers.some(
            (tickers) => tickers.isbn === bottle.isbn
          )
        })
        this.checkedBottleTickers = []
        this.checkedBottleTickersMap = []

        this.getStatistics(payload)
        await this.getAllFolders().then(() => {
          this.isLoading = false
        })
      })
    },
    async refreshChildrenPortfolio(folder) {
      if (folder.children && folder.children.length > 0) {
        folder.children.forEach((childFolder) => {
          this.refreshChildrenPortfolio(childFolder.value)
        })
      }
      await this.getFolderBottles(folder.value)
    },
    async refreshPortfolio() {
      this.isLoading = true
      await this.getFolders()
      await this.getFolderBottles(this.folders[0].value)
      this.folders[0].children.forEach((folder) => {
        this.refreshChildrenPortfolio(folder)
      })
      this.isLoading = false
    },
    closeTooltip() {
      this.clickedOptionItemValueOrTicker = null
    },
    closeRenameFolderModal() {
      this.renameFolderModal = false
      this.refreshPortfolio()
      this.selectedFolderToRename = null
    },
    async deleteSelectedFolder(folderId) {
      this.isLoading = true
      await this.getFolder(folderId)
      this.isLoading = false
      if (this.folder.bottles.length > 0) {
        ElMessage({
          message: this.$t('portfolio.folder_contain_bottles'),
          type: 'error',
          grouping: true,
        })
      } else {
        this.showConfirm = true
        const confirmed = await this.$refs.confirm.confirm()
        if (confirmed) {
          await this.deleteFolder({
            folder_uuid: folderId,
          })
        }
        this.showConfirm = false
      }

      this.clickedOptionItemValueOrTicker = null
    },
    refreshCurrentYearlyReturn() {
      this.bottleList.forEach((o) => {
        o.running_y_return =
          parseFloat(
            o.running_return_number * (1 / (o.diff_today_to_avg / 365))
          ).toFixed(1) + ' %'
      })
    },
    resizeColumnStart(event, index) {
      this.resizingColumnIndex = index
      this.resizingStartX = event.clientX
      this.resizingStartWidth = this.tableHeaders[index].size
        ? Number(this.tableHeaders[index].size)
        : event.target.offsetWidth
    },
    resizeColumn(event) {
      if (this.resizingColumnIndex !== null) {
        const newWidth =
          this.resizingStartWidth + (event.clientX - this.resizingStartX)

        if (newWidth) {
          this.tableHeaders[this.resizingColumnIndex].size =
            newWidth > 20 ? newWidth : 20

          if (this.resizingColumnIndex > 3) {
            this.tableHeaders[0].size += -1
            this.tableHeaders[0].size += 1
          }
        }
      }
    },
    resizeColumnEnd() {
      this.needSettingsUpdate = true
      this.resizingColumnIndex = null
    },
    returnComlumnSize(c) {
      return this.tableHeaders[c + 4].size
    },
    sortBy(primaryType, secondaryType, indexColumn) {
      if (secondaryType === 1 || (primaryType === 1 && !secondaryType)) {
        this.bottleList = this.sortByKey(
          this.bottleList,
          this.tableHeaders[indexColumn].value,
          'asc'
        )
        this.sortType = 'asc'
        this.sortKey = this.tableHeaders[indexColumn].name

        this.$store.commit('SET_SETTINGS_COLUMNS_SORT', {
          name: this.tableHeaders[indexColumn].name,
          sort: 'asc',
          code: [primaryType, secondaryType],
        })
      } else if (secondaryType === 2 || (primaryType === 2 && !secondaryType)) {
        this.bottleList = this.sortByKey(
          this.bottleList,
          this.tableHeaders[indexColumn].value,
          'desc'
        )
        this.sortType = 'desc'
        this.sortKey = this.tableHeaders[indexColumn].name

        this.$store.commit('SET_SETTINGS_COLUMNS_SORT', {
          name: this.tableHeaders[indexColumn].name,
          sort: 'desc',
          code: [primaryType, secondaryType],
        })
      }
    },
    sortByKey(array, key, order) {
      if (!Array.isArray(array)) {
        return
      }

      return array.sort(function (a, b) {
        const splitAlphaNum = (str) => {
          return str.match(/(\d+|\D+)/g)
        }

        const compareSegments = (aSegments, bSegments) => {
          for (
            let i = 0;
            i < Math.max(aSegments.length, bSegments.length);
            i++
          ) {
            const aSegment = aSegments[i] || ''
            const bSegment = bSegments[i] || ''

            const isANumeric = !isNaN(aSegment)
            const isBNumeric = !isNaN(bSegment)

            if (isANumeric && isBNumeric) {
              const diff = parseFloat(aSegment) - parseFloat(bSegment)
              if (diff !== 0) return diff
            } else {
              if (aSegment < bSegment) return -1
              if (aSegment > bSegment) return 1
            }
          }
          return 0
        }

        const aSegments = splitAlphaNum(a[key].toString())
        const bSegments = splitAlphaNum(b[key].toString())

        let comparison = compareSegments(aSegments, bSegments)
        return order === 'desc' ? comparison * -1 : comparison
      })
    },
  },
  watch: {
    async isAllFoldersUnfold(newValue) {
      if (newValue) {
        const folders = await this.getAllFolders()
        this.bottleList = []
        folders.products.forEach((bottle) => {
          this.bottleList.push(bottle)
        })
        if (this.unfoldedFolderIds.length === 0)
          this.unfoldedFolderIds.push(this.folders[0].value)
        this.folders[0].children.forEach((folder) => {
          this.pushFolderChildrenToUnfoldedFolderIds(folder)
        })
      } else {
        this.unfoldedFolderIds = []
      }
    },
    pfmGetBottleList(newValue) {
      this.bottleList = newValue
    },
    bottleList() {
      this.refreshCurrentYearlyReturn()
    },
    folderToUpdateId(newValue) {
      if (newValue) {
        this.getFolderBottles(newValue)
        this.setFolderToUpdateId(null)
      }
    },
    pfmStatistics() {
      this.refreshCurrentYearlyReturn()
    },
    unfoldedFolderIds() {
      if (this.unfoldedFolderIds.length === 0) {
        this.unfoldedFolderIds.push(this.folders[0].value)
      }
    },
    needSettingsUpdate() {
      if (this.needSettingsUpdate && !this.settingsUpdatePending) {
        this.settingsUpdatePending = true

        setTimeout(() => {
          this.$store.commit('SET_SETTINGS_COLUMNS_LAYOUT', this.tableHeaders)
          this.needSettingsUpdate = false
          this.settingsUpdatePending = false

          this.updateSettings({
            currency: 'EUR',
            columns_options: this.pfmSettings.columns_options,
            columns_arrange: this.pfmSettings.columns_arrange,
            columns_layout: this.pfmSettings.columns_layout,
            columns_sort: this.pfmSettings.columns_sort,
          })
        }, 1000)
      }
    },
    checkedBottleTickers() {
      if (this.checkedBottleTickers.length === 0) {
        this.allBottlesChecked = false
        this.numberBottleSelected = 0
        this.checkedBottleTickersMap = []
      }
    },
  },
  async mounted() {
    this.bottleList = this.pfmGetBottleList
    this.isLoading = true
    this.unfoldedFolderIds.push(this.folders[0].value)

    await this.getFolderBottles(this.folders[0].value).then(() => {
      this.isLoading = false
    })

    const folders = await this.getAllFolders()

    this.bottleList = []
    folders.products.forEach((bottle) => {
      this.bottleList.push(bottle)
    })

    this.isLoading = false

    if (this.unfoldedFolderIds.length === 0) {
      this.unfoldedFolderIds.push(this.folders[0].value)
    }

    if (this.pfmSettings.columns_sort) {
      const findColumnIndexName = findIndex(this.tableHeaders, {
        name: this.pfmSettings.columns_sort.name,
      })

      if (findColumnIndexName !== -1) {
        this.sortBy(
          this.pfmSettings.columns_sort.code[0],
          this.pfmSettings.columns_sort.code[1],
          findColumnIndexName
        )
      }
    }

    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';

.no-wrap {
  display: flex;
  align-items: center;
  white-space: nowrap;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 18px;

    img {
      margin-left: 8px;
    }
  }
}

.sort img {
  cursor: pointer;
}

.action-headband {
  height: 50px;
  padding: 0 1.5rem;
  background-color: $primary-color;
  color: var(--text-color);
  border-radius: 12px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
  }

  div button {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: transparent;
    color: $light-color;
    border: none;
    margin-left: 20px;
  }
}

.table-container {
  overflow-x: auto;
  overflow-y: auto;
  position: relative;
  background-color: var(--background-color-2);
  border-radius: 12px;
  max-height: 750px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: var(--background-color);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--bg-constrast);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--background-color-6);
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: var(--text-color) var(--background-color);

    &::-moz-scrollbar-track {
      background: var(--background-color);
    }

    &::-moz-scrollbar-thumb {
      background-color: var(--bg-constrast);
      border-radius: 10px;
    }

    &::-moz-scrollbar-thumb:hover {
      background-color: var(--background-color-6);
    }
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  color: var(--subtitle-2-text-color);

  th {
    min-width: none !important;
    color: var(--text-color);
  }

  thead {
    tr:hover {
      th:not(:first-child) {
        border-right: 1px solid var(--border-grey);
        cursor: col-resize;
      }
    }

    tr {
      position: sticky;
      z-index: 0;
      top: 0;
      background-color: var(--background-color-2);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      th {
        padding: 12px 10px;
      }

      th:first-child {
        position: sticky;
        cursor: initial;
        left: 0;
        z-index: 1;
        background-color: var(--background-color-2);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 1px;
          background-color: var(--border-2);
        }
      }

      th:nth-child(2) {
        > div {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }

      th:last-child {
        position: sticky;
        cursor: initial;
        right: 0;
        z-index: 1;
        background-color: var(--background-color-2);

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 1px;
          background-color: var(--border-2);
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: var(--background-color-3);

        > td:last-child {
          background-color: var(--background-color-3);
        }
      }

      td:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: var(--background-color-2);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 1px;
          background-color: var(--border-2);
        }
      }

      td:last-child {
        position: sticky;
        right: 0;
        background-color: var(--background-color-2);

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 1px;
          background-color: var(--border-2);
          position: relative;
        }

        > div {
          cursor: pointer;
          display: flex;
          align-items: center;

          > img {
            padding: 9px;
          }
        }
      }

      &:nth-child(2n) td:first-child {
        background-color: var(--background-color-3);
      }
    }
  }

  th,
  td {
    padding: 8px 1rem;
    text-align: left;
    white-space: nowrap;
  }

  td {
    color: var(--text-color);
  }

  td:nth-child(2) {
    padding-left: 10px;
  }

  .td-contain-text,
  .th-contain-text {
    overflow: hidden;
  }

  th {
    padding: 1rem;
    border-bottom: 1px solid var(--border-2);
  }
}

img {
  max-height: 20px;
}

.cell-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cell-content-folder {
  cursor: pointer;
}

.cell-name {
  display: flex;
  align-items: center;

  gap: 8px;
}

.bottom {
  max-height: 5px !important;
}

.right {
  max-width: 4.5px !important;
}

.folder-label {
  font-weight: 700;
  color: var(--text-color);
}

.negative-number {
  color: $alert-2-color;
}

.action-headband-scale-enter-active,
.action-headband-scale-leave-active {
  transition: transform 0.3s ease;
}

.action-headband-scale-enter-to,
.action-headband-scale-leave-from {
  transform: scale(1);
}

.action-headband-scale-leave-to,
.action-headband-scale-enter-from {
  transform: scale(0);
}

@media screen and (max-width: 500px) {
  .phone-disabled {
    display: none;
  }
}
</style>
