<template>
  <img
    :src="
      isChecked
        ? require('@/assets/svg/v2/portfolio/checkedCheckbox.svg')
        : require('@/assets/svg/v2/portfolio/uncheckedCheckbox.svg')
    "
    :alt="isChecked ? 'Checked custom checkbox' : 'Unchecked custom checkbox'"
    class="custom-checkbox themed-img"
    :style="{ height: size + 'px', width: size + 'px' }"
    @click="toggleCheckbox"
  />
</template>

<script>
export default {
  name: 'commonCheckbox',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      required: true,
    },
  },
  computed: {
    isChecked() {
      return this.modelValue
    },
  },
  methods: {
    toggleCheckbox() {
      this.$emit('update:modelValue', !this.isChecked)
    },
  },
}
</script>

<style scoped lang="scss">
.custom-checkbox,
.custom-checkbox-checked {
  cursor: pointer;
}
</style>
