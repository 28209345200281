<template>
  <div class="content">
    <div class="selector">
      <div
        class="tab"
        :class="{ selected: selectedTab === 1 }"
        @click="handleSelectTab(1)"
      >
        <img
          :src="require('@/assets/svg/v2/portfolio/panelsIcon.svg')"
          class="themed-img"
          alt="Panels icon"
        />
        {{ $t('portfolio.columns_table') }}
      </div>
      <div
        class="tab"
        :class="{ selected: selectedTab === 2 }"
        @click="handleSelectTab(2)"
      >
        <img
          :src="require('@/assets/svg/v2/portfolio/slidersIcon.svg')"
          class="themed-img"
          alt="Sliders icon"
        />
        {{ $t('portfolio.options_table') }}
      </div>
      <div
        class="tab"
        :class="{ selected: selectedTab === 3 }"
        @click="handleSelectTab(3)"
      >
        <img
          :src="require('@/assets/svg/v2/portfolio/slidersIcon.svg')"
          class="themed-img"
          alt="Sliders icon"
        />
        {{ $t('portfolio.options') }}
      </div>
    </div>

    <div v-if="selectedTab === 1" class="content-main">
      <div class="additional-columns">
        <div class="additional-columns-header">
          <h4 class="title">{{ $t('portfolio.additional_columns') }}</h4>
          <button @click="handleSelectAll">{{ selectAllButtonText }}</button>
        </div>

        <div class="additional-columns-content">
          <div v-for="(item, index) in items" :key="index" class="option">
            {{ checkedItemsMap[item] }}
            <CommonCheckbox
              size="15"
              v-model="checkedItemsMap[item.value]"
              @click="handleClickOption(item)"
            />
            <label
              :for="item.value"
              :class="{ checked: checkedItemsMap[item.value] }"
              >{{ item.label }}</label
            >
          </div>
        </div>
      </div>

      <el-divider class="divider" />

      <div class="columns-order">
        <div class="columns-order-header">
          <h4 class="title">{{ $t('portfolio.column_order') }}</h4>
        </div>

        <draggable :list="checkedItems" item-key="index">
          <template #item="{ element, index }">
            <div class="columns-order-content-item">
              <span class="index">{{ index + 1 }}</span>
              <img
                :src="require('@/assets/svg/v2/portfolio/grip.svg')"
                class="themed-img"
                alt="Grip icon"
              />
              <span class="name">{{ element.label }}</span>
            </div>
          </template>
        </draggable>
      </div>
    </div>

    <div v-if="selectedTab === 2" class="content-main">
      <div class="options">
        <div class="options-header">
          <h4 class="title">{{ $t('portfolio.options') }}</h4>
        </div>

        <div class="options-content">
          <div>
            <img
              :src="require('@/assets/svg/v2/portfolio/circleRowUp.svg')"
              class="themed-img"
              alt="Circle row to upside"
            />
            <span>{{ $t('portfolio.show_sell_stock') }}</span>
          </div>

          <el-switch
            v-model="pfmShowQuantityZero"
            @change="toggleShowQuantityZero"
          />
        </div>
      </div>
    </div>

    <div v-if="selectedTab === 3" class="infos">
      <span v-if="pfmSettings.limit.bottles_count">{{
        $t('portfolio.bottles')
      }}</span>
      <el-progress
        v-if="pfmSettings.limit.bottles_count"
        :percentage="
          (100 * pfmSettings.limit.bottles_count) /
          pfmSettings.limit.bottles_limit
        "
      />
      <span v-if="pfmSettings.limit.tickers_count">{{
        $t('portfolio.tickers')
      }}</span>
      <el-progress
        v-if="pfmSettings.limit.tickers_count"
        :percentage="
          (100 * pfmSettings.limit.tickers_count) /
          pfmSettings.limit.tickers_limit
        "
        :format="format"
      />
    </div>

    <button class="save-button action-light" @click="handleSaveOptions">
      {{ $t('commons.apply') }}
    </button>
  </div>
</template>

<script>
import CommonCheckbox from '@/components/commons/checkbox.vue'
import Draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'drawerSettingsContent',
  components: { CommonCheckbox, Draggable },
  emits: ['close'],
  data() {
    return {
      selectedTab: 1,
      items: [],
      checkedItems: [],
    }
  },
  methods: {
    ...mapActions({
      updateSettings: 'updateSettings',
    }),
    handleSelectTab(selectedTabNumber) {
      this.selectedTab = selectedTabNumber
    },
    toggleShowQuantityZero() {
      this.$store.commit('SET_SHOW_QUANTITY_ZERO', !this.pfmShowQuantityZero)
    },
    handleClickOption(item) {
      if (this.checkedItems.includes(item)) {
        const optionIndex = this.checkedItems.indexOf(item)
        this.checkedItems.splice(optionIndex, 1)
      } else {
        this.checkedItems.push(item)
      }
    },
    handleSelectAll() {
      if (this.checkedItems.length !== this.items.length) {
        this.checkedItems = [...this.items]
      } else {
        this.checkedItems = []
      }
    },
    handleSaveOptions() {
      const newColumnOptions = this.checkedItems.map((item) => item.value)
      this.updateSettings({
        currency: 'EUR',
        columns_options: [...newColumnOptions],
        columns_arrange: [...newColumnOptions],
        columns_layout: this.pfmSettings.columns_layout,
        columns_sort: this.pfmSettings.columns_sort,
      })

      this.$emit('close')
    },
    setCheckedItems() {
      this.checkedItems = this.pfmSettings.columns_arrange
        .map((option) => this.items.find((item) => item.value === option))
        .filter((item) => item !== undefined)
    },
  },
  computed: {
    ...mapGetters({
      pfmShowQuantityZero: 'pfmShowQuantityZero',
      getLists: 'getLists',
      pfmSettings: 'pfmSettings',
    }),
    checkedItemsMap() {
      const map = {}
      this.items.forEach((item) => {
        map[item.value] = this.checkedItems.some(
          (checkedItem) => checkedItem.value === item.value
        )
      })
      return map
    },
    selectAllButtonText() {
      return this.checkedItems.length === this.items.length
        ? this.$t('portfolio.unselect_all')
        : this.$t('portfolio.select_all')
    },
  },
  mounted() {
    this.items = this.getLists.table_columns_options.filter((o) => {
      return o.value !== 'brand' && o.value !== 'sub_asset_class'
    })
    this.setCheckedItems()
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';

.content {
  height: 100%;
  max-height: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .infos {
    flex-grow: 1;
    flex-direction: column;
    gap: 1rem;
    color: var(--subtitle-3-text-color);
  }
  .infos .el-progress--line {
    margin-bottom: 15px;
  }

  .selector {
    border: 1px solid var(--border-2);
    border-radius: 12px;
    padding: 8px;
    margin-bottom: 1rem;

    display: flex;
    justify-content: space-evenly;

    .tab {
      color: var(--subtitle-3-text-color);
      padding: 5px 15px;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      gap: 5px;

      > img {
        opacity: 0.6;
      }
    }

    .selected {
      background-color: var(--background-color-7);
      border-radius: 8px;

      > img {
        opacity: 1;
      }
    }
  }

  .content-main {
    flex-grow: 1;
    max-height: 85%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .divider {
    margin: 2rem 0;
  }

  .additional-columns {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .additional-columns-header {
      color: var(--text-color);
      display: flex;
      justify-content: space-between;

      > button {
        padding: 5px 10px;
        border-radius: 8px;
        background-color: transparent;
        border: 1px solid var(--border-2);
        color: var(--subtitle-3-text-color);
      }
    }

    .additional-columns-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      overflow-y: auto;
      color: var(--subtitle-3-text-color);

      .option {
        display: flex;
        align-items: center;
        gap: 8px;

        label.checked {
          color: var(--text-color);
        }
      }
    }
  }

  .columns-order {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .columns-order-header {
      color: var(--text-color);
      margin-bottom: 1rem;
    }
  }

  .columns-order-content-item {
    margin-top: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;

    .index {
      color: var(--subtitle-3-text-color);
      background-color: var(--background-color-16);
      height: 30px;
      width: 30px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .name {
      color: var(--text-color);
      font-weight: 500;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .options-header {
      color: var(--text-color);
    }

    .options-content {
      color: var(--subtitle-3-text-color);
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .save-button {
    padding: 1rem;
    border: none;
    border-radius: 52px;
    background-color: $primary-color;
    margin-bottom: 1rem;
  }
}
</style>
